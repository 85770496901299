<template>
    <v-container>
        <AdBTWR v-if="!user.isAdFree"/>

        <h1 class="page-header font-weight-light">My Requests</h1>

        <div v-if="user.authenticated" class="mb-15">
            <Requests :user="user" />
        </div>
        <div v-else>
            <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                You must be logged in to manage your repeater requests.
            </v-alert>
        </div>

    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import Requests from '@/components/Profile/Requests';
    import { useTitle } from '@vueuse/core';

    export default {
        name: 'MyRequests',

        props: ['user'],

        components: { AdBTWR, Requests },

        mounted() {
            useTitle('My Requests - myGMRS.com');
        }
    }
</script>
